var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "sx-page",
    { staticClass: "admin-page", attrs: { title: "Admin-Panel" } },
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.adminPanelTabIndex,
            callback: function ($$v) {
              _vm.adminPanelTabIndex = $$v
            },
            expression: "adminPanelTabIndex",
          },
        },
        [
          _c("v-tab", [_vm._v("Benutzer")]),
          _c("v-tab", [_vm._v("Verträge")]),
          _c("v-tab", [_vm._v("Mailing")]),
          _c("v-tab", [
            _vm._v("Opportunity" + _vm._s(_vm.getTabContractLabel(3))),
          ]),
          _c("v-tab", [_vm._v("Skills" + _vm._s(_vm.getTabContractLabel(4)))]),
          _c("v-tab", [
            _vm._v("Customers" + _vm._s(_vm.getTabContractLabel(5))),
          ]),
          _c("v-tab", [_vm._v("Sonstiges")]),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "admin-tabs",
          model: {
            value: _vm.adminPanelTabIndex,
            callback: function ($$v) {
              _vm.adminPanelTabIndex = $$v
            },
            expression: "adminPanelTabIndex",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "sx-editor-table",
                {
                  ref: "userEditorTable",
                  attrs: {
                    editorId: "user",
                    rows: _vm.users,
                    searchVisible: true,
                    dialogTitle: "Benutzer",
                    onAction: _vm.onEditorAction,
                    columns: [
                      {
                        width: 90,
                        field: "enabled",
                        headerName: "Aktiv",
                        cellRenderer: function (params) {
                          return _vm.showTableCellBoolean(params)
                        },
                      },
                      {
                        field: "username",
                        headerName: "Benutzername",
                        flex: 1,
                      },
                      { field: "name", headerName: "Name", width: 150 },
                      {
                        field: "familyName",
                        headerName: "Nachname",
                        width: 150,
                      },
                      {
                        field: "phoneNumber",
                        headerName: "Telefon",
                        width: 160,
                      },
                      {
                        flex: 1,
                        field: "groups",
                        headerName: "Gruppen",
                        cellRenderer: function (params) {
                          return _vm.showTableCellList(params)
                        },
                      },
                      {
                        field: "Aktionen",
                        width: 110,
                        cellRendererFramework: "SxEditorTableCell",
                        cellRendererParams: {
                          editorId: "user",
                          hideDelete: function (item) {
                            return item.enabled
                          },
                        },
                      },
                    ],
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "dialogContent" },
                    [
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "uname",
                          label: "Benutzername (E-Mail)",
                          placeholder: "vorname.nachname@domain.com",
                          disabled: !_vm.isCreate,
                        },
                        model: {
                          value: _vm.editUser.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.editUser, "username", $$v)
                          },
                          expression: "editUser.username",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "fname",
                          label: "Vorname",
                          disabled: true,
                        },
                        model: {
                          value: _vm.editUser.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editUser, "name", $$v)
                          },
                          expression: "editUser.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "lname",
                          label: "Nachname",
                          disabled: true,
                        },
                        model: {
                          value: _vm.editUser.familyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editUser, "familyName", $$v)
                          },
                          expression: "editUser.familyName",
                        },
                      }),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isCreate,
                            expression: "!isCreate",
                          },
                        ],
                        ref: "userPhoneNumber",
                        staticClass: "text phoneNumber",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "phone",
                          label: "Telefon",
                          rules: _vm.rulePhoneNumber,
                          hint: "Die Telefonnummer sollte folgenden Format haben, z.B. +49123456789.",
                          disabled:
                            _vm.editUser.phoneNumber == "[Phone Number]",
                          "persistent-hint": "",
                        },
                        model: {
                          value: _vm.editUser.phoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.editUser, "phoneNumber", $$v)
                          },
                          expression: "editUser.phoneNumber",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "sx-box" },
                        _vm._l(
                          _vm.$store.getters["group/getGroups"],
                          function (group, index) {
                            return _c("v-checkbox", {
                              key: index,
                              attrs: {
                                label: group.name,
                                "input-value": _vm.isInGroup(
                                  _vm.editUser.groups,
                                  group
                                ),
                                disabled: _vm.isCiscoOrSibe[index],
                              },
                              on: {
                                change: function ($event) {
                                  _vm.onChangeGroup($event, group),
                                    _vm.toggleCiscoOrSibe(group.name)
                                },
                              },
                            })
                          }
                        ),
                        1
                      ),
                      _c("v-switch", {
                        class: {
                          "sx-account-active": _vm.editUser.enabled,
                          "sx-account-disabled": !_vm.editUser.enabled,
                        },
                        attrs: {
                          label:
                            "Benutzerkonto " +
                            (_vm.editUser.enabled
                              ? "aktiviert"
                              : "deaktiviert"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onChangeDisabledState($event)
                          },
                        },
                        model: {
                          value: _vm.editUser.enabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.editUser, "enabled", $$v)
                          },
                          expression: "editUser.enabled",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("p", { staticClass: "table-info-text" }, [
                _vm._v(
                  "Benutzerkonten können erst nach Deaktivierung gelöscht werden. "
                ),
                _c("br"),
                _vm._v(" Benutzername: max.muster@computacenter.com"),
                _c("br"),
                _vm._v(" Standard-Passwort: max.muster"),
              ]),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "sx-editor-table",
                {
                  ref: "contractEditorTable",
                  attrs: {
                    rows: _vm.$store.getters["contract/getContracts"],
                    editorId: "contract",
                    searchVisible: true,
                    dialogTitle: _vm.editContractDialogTitle,
                    onAction: _vm.onEditorAction,
                    columns: [
                      { field: "name", headerName: "Name", flex: 1 },
                      {
                        field: "Aktionen",
                        width: 110,
                        cellRendererFramework: "SxEditorTableCell",
                        cellRendererParams: {
                          editorId: "contract",
                          hideEdit: function (item) {
                            return true
                          },
                        },
                      },
                    ],
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "dialogContent" },
                    [
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cname",
                          label: "Name",
                        },
                        model: {
                          value: _vm.editContract.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editContract, "name", $$v)
                          },
                          expression: "editContract.name",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "contract-colorpicker" },
                        [
                          _c("p", [_vm._v("Farbe")]),
                          _c("v-color-picker", {
                            attrs: {
                              "dot-size": "25",
                              "swatches-max-height": "200",
                            },
                            model: {
                              value: _vm.editContract.color,
                              callback: function ($$v) {
                                _vm.$set(_vm.editContract, "color", $$v)
                              },
                              expression: "editContract.color",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c("v-tab-item", [
            _c(
              "div",
              { staticClass: "sx-padding" },
              [
                _c("v-select", {
                  attrs: {
                    items: this.$store.state.app.context.mailTemplates,
                    label: "Einhängepunkt",
                    "item-text": "name",
                    "return-object": "",
                    outlined: "",
                    dense: "",
                  },
                  model: {
                    value: _vm.mailTemplate,
                    callback: function ($$v) {
                      _vm.mailTemplate = $$v
                    },
                    expression: "mailTemplate",
                  },
                }),
                _vm.mailTemplate != null
                  ? [
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "receiver",
                          label: "Empfänger",
                        },
                        model: {
                          value: _vm.mailTemplate.receiver,
                          callback: function ($$v) {
                            _vm.$set(_vm.mailTemplate, "receiver", $$v)
                          },
                          expression: "mailTemplate.receiver",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cc",
                          label: "CC",
                        },
                        model: {
                          value: _vm.mailTemplate.ccReceiver,
                          callback: function ($$v) {
                            _vm.$set(_vm.mailTemplate, "ccReceiver", $$v)
                          },
                          expression: "mailTemplate.ccReceiver",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "bcc",
                          label: "BCC",
                        },
                        model: {
                          value: _vm.mailTemplate.bccReceiver,
                          callback: function ($$v) {
                            _vm.$set(_vm.mailTemplate, "bccReceiver", $$v)
                          },
                          expression: "mailTemplate.bccReceiver",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "subject",
                          label: "Betreff",
                        },
                        model: {
                          value: _vm.mailTemplate.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.mailTemplate, "subject", $$v)
                          },
                          expression: "mailTemplate.subject",
                        },
                      }),
                      _c("v-textarea", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          "auto-grow": "",
                          name: "body",
                          label: "Inhalt",
                        },
                        model: {
                          value: _vm.mailTemplate.body,
                          callback: function ($$v) {
                            _vm.$set(_vm.mailTemplate, "body", $$v)
                          },
                          expression: "mailTemplate.body",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "sx-flex-buttons" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "secondary" },
                              on: { click: _vm.onOpenMailDocumentation },
                            },
                            [_vm._v("Dokumentation")]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "secondary" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.onOpenMail.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("E-Mail Programm öffnen")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "primary" },
                              on: { click: _vm.onSaveAppContext },
                            },
                            [_vm._v("Vorlage speichern")]
                          ),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _c(
            "v-tab-item",
            [
              _c("sx-editor-table", {
                ref: "opportunityEditorTable",
                attrs: {
                  disableNew: true,
                  rows: _vm.opportunities,
                  editorId: "opportunity",
                  onAction: _vm.onEditorAction,
                  searchVisible: true,
                  columns: [
                    {
                      headerName: "Opp-ID",
                      width: 120,
                      field: "version",
                      sort: "desc",
                      comparator: function (valueA, valueB) {
                        return (
                          this$1.strToFloat(valueA) - this$1.strToFloat(valueB)
                        )
                      },
                    },
                    { field: "name", headerName: "Name", flex: 1 },
                    {
                      headerName: "Status",
                      field: "state",
                      unSortIcon: true,
                      width: 130,
                    },
                    {
                      headerName: "KAM",
                      field: "cC_KAM",
                      unSortIcon: true,
                      width: 130,
                    },
                    { headerName: "Opportunity Name", field: "name", flex: 1 },
                    { headerName: "Kunde", field: "customerName", flex: 1 },
                    {
                      field: "Aktionen",
                      width: 110,
                      cellRendererFramework: "SxEditorTableCell",
                      cellRendererParams: {
                        editorId: "opportunity",
                        hideEdit: function (item) {
                          return true
                        },
                      },
                    },
                  ],
                },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "sx-editor-table",
                {
                  ref: "skillEditorTable",
                  attrs: {
                    rows: _vm.skillTableData,
                    editorId: "skill",
                    dialogTitle: "Skill",
                    onAction: _vm.onEditorAction,
                    searchVisible: true,
                    columns: [
                      { field: "name", flex: 1 },
                      {
                        cellRenderer: function (params) {
                          return _vm.$options.filters.formatNumber(
                            params.value,
                            "0,0.00 $"
                          )
                        },
                        headerName: "bis 40 PT",
                        field: "lessThan40PT",
                        width: 180,
                      },
                      {
                        cellRenderer: function (params) {
                          return _vm.$options.filters.formatNumber(
                            params.value,
                            "0,0.00 $"
                          )
                        },
                        headerName: "41 bis 160 PT",
                        field: "between41To160PT",
                        width: 180,
                      },
                      {
                        cellRenderer: function (params) {
                          return _vm.$options.filters.formatNumber(
                            params.value,
                            "0,0.00 $"
                          )
                        },
                        headerName: "mehr als 160 PT",
                        field: "moreThan160PT",
                        width: 180,
                      },
                      { field: "skillNames", headerName: "Namen", width: 180 },
                      {
                        field: "Aktionen",
                        width: 110,
                        cellRendererFramework: "SxEditorTableCell",
                        cellRendererParams: { editorId: "skill" },
                      },
                    ],
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "dialogContent" },
                    [
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "sname",
                          label: "Name",
                        },
                        model: {
                          value: _vm.editSkill.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "name", $$v)
                          },
                          expression: "editSkill.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p40",
                          label: "Preis bis 40 PT",
                        },
                        model: {
                          value: _vm.editSkill.lessThan40PT,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "lessThan40PT", $$v)
                          },
                          expression: "editSkill.lessThan40PT",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p41t160",
                          label: "Preis von 41 bis 160 PT",
                        },
                        model: {
                          value: _vm.editSkill.between41To160PT,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "between41To160PT", $$v)
                          },
                          expression: "editSkill.between41To160PT",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p160",
                          label: "Preis ab 160 PT",
                        },
                        model: {
                          value: _vm.editSkill.moreThan160PT,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "moreThan160PT", $$v)
                          },
                          expression: "editSkill.moreThan160PT",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p40",
                          label: 'Name für "bis 40 PT"',
                        },
                        model: {
                          value: _vm.editSkill.lessThan40PTName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "lessThan40PTName", $$v)
                          },
                          expression: "editSkill.lessThan40PTName",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p41t160",
                          label: 'Name für "von 41 bis 160 PT"',
                        },
                        model: {
                          value: _vm.editSkill.between41To160PTName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "between41To160PTName", $$v)
                          },
                          expression: "editSkill.between41To160PTName",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "p160",
                          label: 'Name für "ab 160 PT"',
                        },
                        model: {
                          value: _vm.editSkill.moreThan160PTName,
                          callback: function ($$v) {
                            _vm.$set(_vm.editSkill, "moreThan160PTName", $$v)
                          },
                          expression: "editSkill.moreThan160PTName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("p", { staticClass: "table-info-text" }, [
                _vm._v(
                  "Die Reisekostenpauschale ist auf jeden Skill in Höhe von 120,- EUR pro Tag mit zu kalkulieren"
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "sx-editor-table",
                {
                  ref: "customerEditorTable",
                  attrs: {
                    rows: _vm.customers,
                    editorId: "customer",
                    searchVisible: true,
                    onAction: _vm.onEditorAction,
                    dialogTitle: _vm.editCustomerDialogTitle,
                    columns: [
                      { field: "name", flex: 1 },
                      { field: "cC_KAM", headerName: "CC KAM", width: 130 },
                      { field: "cC_SM", headerName: "CC SM", width: 130 },
                      {
                        field: "cisco_Emails",
                        headerName: "Cisco E-Mails",
                        flex: 1,
                      },
                      {
                        field: "Aktionen",
                        width: 110,
                        cellRendererFramework: "SxEditorTableCell",
                        cellRendererParams: { editorId: "customer" },
                      },
                    ],
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "dialogContent" },
                    [
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cname",
                          label: "Name",
                        },
                        model: {
                          value: _vm.editCustomer.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editCustomer, "name", $$v)
                          },
                          expression: "editCustomer.name",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cname",
                          label: "Computacenter Key Account Manager",
                          placeholder: "Nachname, Vorname",
                        },
                        model: {
                          value: _vm.editCustomer.cC_KAM,
                          callback: function ($$v) {
                            _vm.$set(_vm.editCustomer, "cC_KAM", $$v)
                          },
                          expression: "editCustomer.cC_KAM",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cname",
                          label: "Computacenter Service Manager",
                          placeholder: "Nachname, Vorname",
                        },
                        model: {
                          value: _vm.editCustomer.cC_SM,
                          callback: function ($$v) {
                            _vm.$set(_vm.editCustomer, "cC_SM", $$v)
                          },
                          expression: "editCustomer.cC_SM",
                        },
                      }),
                      _c("v-text-field", {
                        staticClass: "text",
                        attrs: {
                          dense: "",
                          outlined: "",
                          name: "cname",
                          label:
                            "Cisco E-Mails (semikolon separiert: mail;mail)",
                          placeholder:
                            "Semikolon separierte Mail-Liste (mail;mail)",
                        },
                        model: {
                          value: _vm.editCustomer.cisco_Emails,
                          callback: function ($$v) {
                            _vm.$set(_vm.editCustomer, "cisco_Emails", $$v)
                          },
                          expression: "editCustomer.cisco_Emails",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-tab-item",
            [
              _c(
                "div",
                { staticClass: "sx-padding admin-form" },
                [
                  _c("div", { staticClass: "admin-form-text" }, [
                    _c("span", [_vm._v("Opportunities")]),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      suffix: "Tagen",
                      label: "Opportunities ausblenden nach",
                      outlined: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.$store.state.app.context.opportunityDaysFilter,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$store.state.app.context,
                          "opportunityDaysFilter",
                          _vm._n($$v)
                        )
                      },
                      expression:
                        "$store.state.app.context.opportunityDaysFilter",
                    },
                  }),
                  _c("div", { staticClass: "admin-form-text" }, [
                    _c("span", [_vm._v("Authentifizierung")]),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "primary" },
                      on: { click: _vm.onCopyBearerToken },
                    },
                    [_vm._v("Bearer Token kopieren")]
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "admin-form-bottom-right",
                  attrs: { small: "", color: "primary" },
                  on: { click: _vm.onSaveAppContext },
                },
                [_vm._v("Änderungen speichern ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "sx-dialog",
        {
          ref: "mailDocumentation",
          attrs: { width: 800, title: "Dokumentation für Mail-Vorlage" },
        },
        [
          _c("div", { staticClass: "docs" }, [
            _c("p", [
              _vm._v(
                " Diese Seite steuert die Mail-Vorlage, die an ein externes Programm (z.B. Outlook) überreicht wird. "
              ),
              _c("br"),
              _vm._v(" Der Button erscheint in der tabellarischen Übersicht ("),
              _c("b", [_vm._v("CC-User")]),
              _vm._v("), sofern der Status einer Opportunity auf "),
              _c("b", [_vm._v("genehmigt")]),
              _vm._v(" oder "),
              _c("b", [_vm._v("abgelehnt")]),
              _vm._v(" ist. "),
              _c("br"),
              _c("br"),
              _vm._v(
                " Die Vorlage ersetzt Platzhalter im Subject und Body mit den Werten der Opportunity: "
              ),
              _c("br"),
              _c("br"),
              _c("b", [_vm._v('Aus "{customerName}" wird "Muster AG".')]),
              _c("br"),
              _c("br"),
              _c("b", [
                _vm._v("Hier ist eine Auflistung aller möglichen Platzhalter:"),
              ]),
              _c("br"),
              _c("br"),
              _c("pre", [
                _vm._v(
                  "          {id}                = 100 \n          {version}           = 36.1 \n          {customerName}      = Wasserstraßen- und Schifffahrtsamt Koblenz \n          {description}       = test \n          {name}              = test \n          {state}             = Qualifiziert \n          {stateDescription}  = supersuper\n          {contractName}      = Netzwerk 21082\n          {cisco_Emails}      = cisco1@cisco.com;cisco2@cisco.com\n          {cC_KAM}            = Key Account Manager von Computacenter \n          {createdAt}         = 19.07.2021\n          {creatorUserEmail}  = ersteller@computacenter.com\n          {createdBy}         = Test, Computa \n          {modifiedAt}        = 19.07.2021\n          {modifiedBy}        = Test, Cisco \n          {totalCost}         = 1.000,00 € \n          {startDate}         = 19.07.2021 \n          {duration}          = 3 \n          "
                ),
              ]),
              _c("br"),
              _c("br"),
              _c("b", [_vm._v("Sample:")]),
              _c("br"),
              _c("br"),
              _vm._v(
                ' Projekt "{name}" ({id}) von {customerName} zum {startDate} für {totalCost} '
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                ' Projekt "test" (100) von Wasserstraßen- und Schifffahrtsamt Koblenz zum 19.07.2021 für 1.000,00 € '
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }