<template>
  <sx-page class="admin-page" title="Admin-Panel">

    <v-tabs v-model="adminPanelTabIndex">
      <v-tab>Benutzer</v-tab>
      <v-tab>Verträge</v-tab>
      <v-tab>Mailing</v-tab>
      <v-tab>Opportunity{{ getTabContractLabel(3) }}</v-tab>
      <v-tab>Skills{{ getTabContractLabel(4) }}</v-tab>
      <v-tab>Customers{{ getTabContractLabel(5) }}</v-tab>
      <v-tab>Sonstiges</v-tab>
    </v-tabs>

    <v-tabs-items v-model="adminPanelTabIndex" class="admin-tabs">
      <v-tab-item>
        <sx-editor-table editorId="user" :rows="users" :searchVisible="true" ref="userEditorTable"
          dialogTitle="Benutzer" :onAction="onEditorAction" :columns="[
            {
              width: 90,
              field: 'enabled',
              headerName: 'Aktiv',
              cellRenderer: params => showTableCellBoolean(params)
            },
            { field: 'username', headerName: 'Benutzername', flex: 1 },
            { field: 'name', headerName: 'Name', width: 150 },
            { field: 'familyName', headerName: 'Nachname', width: 150 },
            { field: 'phoneNumber', headerName: 'Telefon', width: 160 },
            {
              flex: 1,
              field: 'groups',
              headerName: 'Gruppen',
              cellRenderer: params => showTableCellList(params)
            },
            {
              field: 'Aktionen',
              width: 110,
              cellRendererFramework: 'SxEditorTableCell',
              cellRendererParams: { editorId: 'user', hideDelete: (item) => item.enabled }
            }
          ]">
          <template slot="dialogContent">
            <v-text-field dense outlined name="uname" class="text" label="Benutzername (E-Mail)"
              placeholder="vorname.nachname@domain.com" :disabled="!isCreate" v-model="editUser.username">
            </v-text-field>
            <v-text-field dense outlined class="text" name="fname" label="Vorname" :disabled="true"
              v-model="editUser.name"></v-text-field>
            <v-text-field dense outlined class="text" name="lname" label="Nachname" :disabled="true"
              v-model="editUser.familyName"></v-text-field>

            <v-text-field dense outlined class="text phoneNumber" name="phone" label="Telefon" v-show="!isCreate"
              :rules="rulePhoneNumber" hint="Die Telefonnummer sollte folgenden Format haben, z.B. +49123456789."
              :disabled="editUser.phoneNumber == '[Phone Number]'" v-model="editUser.phoneNumber" ref="userPhoneNumber"
              persistent-hint>
            </v-text-field>

            <div class="sx-box">
              <v-checkbox :key="index" :label="group.name" :input-value="isInGroup(editUser.groups, group)"
                @change="onChangeGroup($event, group), toggleCiscoOrSibe(group.name)"
                v-for="(group, index) in $store.getters['group/getGroups']" :disabled="isCiscoOrSibe[index]" />
            </div>
            <v-switch v-model="editUser.enabled" :class="{
              'sx-account-active': editUser.enabled,
              'sx-account-disabled': !editUser.enabled
            }" @change="$event => onChangeDisabledState($event)"
              :label="'Benutzerkonto ' + ((editUser.enabled) ? 'aktiviert' : 'deaktiviert')" />
          </template>
        </sx-editor-table>
        <p class="table-info-text">Benutzerkonten können erst nach Deaktivierung gelöscht werden. <br>
          Benutzername: max.muster@computacenter.com<br>
          Standard-Passwort: max.muster</p>
      </v-tab-item>
      <v-tab-item>
        <sx-editor-table :rows="$store.getters['contract/getContracts']" editorId="contract" :searchVisible="true"
          :dialogTitle="editContractDialogTitle" :onAction="onEditorAction" ref="contractEditorTable" :columns="[
            { field: 'name', headerName: 'Name', flex: 1 },
            {
              field: 'Aktionen',
              width: 110,
              cellRendererFramework: 'SxEditorTableCell',
              cellRendererParams: { editorId: 'contract', hideEdit: (item) => true }
            }
          ]">
          <template slot="dialogContent">
            <v-text-field dense outlined name="cname" label="Name" class="text" v-model="editContract.name">
            </v-text-field>
            <div class="contract-colorpicker">
              <p>Farbe</p>
              <v-color-picker dot-size="25" swatches-max-height="200" v-model="editContract.color"></v-color-picker>
            </div>
          </template>
        </sx-editor-table>
      </v-tab-item>
      <!-- CTRL + F: Mailing -->
      <v-tab-item>
        <div class="sx-padding">
          <v-select :items="this.$store.state.app.context.mailTemplates" v-model="mailTemplate" label="Einhängepunkt"
            item-text="name" return-object outlined dense></v-select>
          <template v-if="mailTemplate != null">
            <v-text-field dense outlined class="text" name="receiver" label="Empfänger"
              v-model="mailTemplate.receiver" />
            <v-text-field dense outlined name="cc" label="CC" class="text" v-model="mailTemplate.ccReceiver" />
            <v-text-field dense outlined name="bcc" label="BCC" class="text" v-model="mailTemplate.bccReceiver" />
            <v-text-field dense outlined class="text" name="subject" label="Betreff" v-model="mailTemplate.subject" />
            <v-textarea dense outlined auto-grow name="body" label="Inhalt" v-model="mailTemplate.body" />
            <div class="sx-flex-buttons">
              <v-btn small color="secondary" @click="onOpenMailDocumentation">Dokumentation</v-btn>
              <v-spacer />
              <v-btn small color="secondary" @click.native="onOpenMail">E-Mail Programm öffnen</v-btn>
              <v-btn small color="primary" @click="onSaveAppContext">Vorlage speichern</v-btn>
            </div>
          </template>
        </div>
      </v-tab-item>
      <v-tab-item>
        <sx-editor-table :disableNew="true" :rows="opportunities" editorId="opportunity" :onAction="onEditorAction"
          ref="opportunityEditorTable" :searchVisible="true" :columns="[
            {
              headerName: 'Opp-ID',
              width: 120,
              field: 'version',
              sort: 'desc',
              comparator: (valueA, valueB) => {
                return this.strToFloat(valueA) - this.strToFloat(valueB);
              },
            },
            { field: 'name', headerName: 'Name', flex: 1 },
            {
              headerName: 'Status',
              field: 'state',
              unSortIcon: true,
              width: 130,
            },
            {
              headerName: 'KAM',
              field: 'cC_KAM',
              unSortIcon: true,
              width: 130,
            },
            { headerName: 'Opportunity Name', field: 'name', flex: 1 },
            { headerName: 'Kunde', field: 'customerName', flex: 1 },
            {
              field: 'Aktionen',
              width: 110,
              cellRendererFramework: 'SxEditorTableCell',
              cellRendererParams: { editorId: 'opportunity', hideEdit: (item) => true }
            }
          ]">
        </sx-editor-table>
      </v-tab-item>
      <v-tab-item>
        <sx-editor-table :rows="skillTableData" editorId="skill" dialogTitle="Skill" ref="skillEditorTable"
          :onAction="onEditorAction" :searchVisible="true" :columns="[
            { field: 'name', flex: 1 },
            {
              cellRenderer: params => $options.filters.formatNumber(params.value, '0,0.00 $'),
              headerName: 'bis 40 PT',
              field: 'lessThan40PT',
              width: 180
            },
            {
              cellRenderer: params => $options.filters.formatNumber(params.value, '0,0.00 $'),
              headerName: '41 bis 160 PT',
              field: 'between41To160PT',
              width: 180
            },
            {
              cellRenderer: params => $options.filters.formatNumber(params.value, '0,0.00 $'),
              headerName: 'mehr als 160 PT',
              field: 'moreThan160PT',
              width: 180
            },
            { field: 'skillNames', headerName: 'Namen', width: 180 },
            {
              field: 'Aktionen',
              width: 110,
              cellRendererFramework: 'SxEditorTableCell',
              cellRendererParams: { editorId: 'skill' }
            }
          ]">
          <template slot="dialogContent">
            <v-text-field dense outlined name="sname" label="Name" class="text" v-model="editSkill.name"></v-text-field>
            <v-text-field dense outlined name="p40" class="text" label="Preis bis 40 PT"
              v-model="editSkill.lessThan40PT" />
            <v-text-field dense outlined class="text" name="p41t160" label="Preis von 41 bis 160 PT"
              v-model="editSkill.between41To160PT" />
            <v-text-field dense outlined name="p160" class="text" label="Preis ab 160 PT"
              v-model="editSkill.moreThan160PT" />
            <v-text-field dense outlined name="p40" class="text" label='Name für "bis 40 PT"'
              v-model="editSkill.lessThan40PTName" />
            <v-text-field dense outlined class="text" name="p41t160" label='Name für "von 41 bis 160 PT"'
              v-model="editSkill.between41To160PTName" />
            <v-text-field dense outlined name="p160" class="text" label='Name für "ab 160 PT"'
              v-model="editSkill.moreThan160PTName" />
          </template>
        </sx-editor-table>
        <p class="table-info-text">Die Reisekostenpauschale ist auf jeden Skill in Höhe von 120,- EUR pro Tag mit zu
          kalkulieren</p>
      </v-tab-item>
      <v-tab-item>
        <sx-editor-table :rows="customers" editorId="customer" :searchVisible="true" ref="customerEditorTable"
          :onAction="onEditorAction" :dialogTitle="editCustomerDialogTitle" :columns="[
            { field: 'name', flex: 1 },
            { field: 'cC_KAM', headerName: 'CC KAM', width: 130 },
            { field: 'cC_SM', headerName: 'CC SM', width: 130 },
            { field: 'cisco_Emails', headerName: 'Cisco E-Mails', flex: 1 },
            {
              field: 'Aktionen',
              width: 110,
              cellRendererFramework: 'SxEditorTableCell',
              cellRendererParams: { editorId: 'customer' }
            }
          ]">
          <template slot="dialogContent">
            <v-text-field dense outlined name="cname" label="Name" class="text" v-model="editCustomer.name">
            </v-text-field>
            <v-text-field dense outlined name="cname" label="Computacenter Key Account Manager"
              placeholder="Nachname, Vorname" class="text" v-model="editCustomer.cC_KAM"></v-text-field>
            <v-text-field dense outlined name="cname" label="Computacenter Service Manager"
              placeholder="Nachname, Vorname" class="text" v-model="editCustomer.cC_SM"></v-text-field>  
            <v-text-field dense outlined name="cname" label="Cisco E-Mails (semikolon separiert: mail;mail)"
              placeholder="Semikolon separierte Mail-Liste (mail;mail)" class="text"
              v-model="editCustomer.cisco_Emails"></v-text-field>
          </template>
        </sx-editor-table>
      </v-tab-item>
      <v-tab-item>
        <div class="sx-padding admin-form">
          <div class="admin-form-text">
            <span>Opportunities</span>
          </div>
          <v-text-field type="number" v-model.number="$store.state.app.context.opportunityDaysFilter" suffix="Tagen"
            label="Opportunities ausblenden nach" outlined dense></v-text-field>
          <div class="admin-form-text">
            <span>Authentifizierung</span>
          </div>
          <v-btn small color="primary" @click="onCopyBearerToken">Bearer Token kopieren</v-btn>
        </div>
        <v-btn small color="primary" class="admin-form-bottom-right" @click="onSaveAppContext">Änderungen speichern
        </v-btn>
      </v-tab-item>
      <!-- CTRL + F: end of tabs -->
    </v-tabs-items>
    <sx-dialog :width="800" ref="mailDocumentation" title="Dokumentation für Mail-Vorlage">
      <div class="docs">
        <p>
          Diese Seite steuert die Mail-Vorlage, die an ein externes Programm (z.B. Outlook) überreicht wird.
          <br>
          Der Button erscheint in der tabellarischen Übersicht (<b>CC-User</b>), sofern der Status einer Opportunity auf
          <b>genehmigt</b> oder <b>abgelehnt</b> ist.
          <br>
          <br>
          Die Vorlage ersetzt Platzhalter im Subject und Body mit den Werten der Opportunity:
          <br><br>
          <b>Aus "{customerName}" wird "Muster AG".</b>
          <br><br>
          <b>Hier ist eine Auflistung aller möglichen Platzhalter:</b>
          <br><br>
        <pre>
            {id}                = 100 
            {version}           = 36.1 
            {customerName}      = Wasserstraßen- und Schifffahrtsamt Koblenz 
            {description}       = test 
            {name}              = test 
            {state}             = Qualifiziert 
            {stateDescription}  = supersuper
            {contractName}      = Netzwerk 21082
            {cisco_Emails}      = cisco1@cisco.com;cisco2@cisco.com
            {cC_KAM}            = Key Account Manager von Computacenter 
            {createdAt}         = 19.07.2021
            {creatorUserEmail}  = ersteller@computacenter.com
            {createdBy}         = Test, Computa 
            {modifiedAt}        = 19.07.2021
            {modifiedBy}        = Test, Cisco 
            {totalCost}         = 1.000,00 € 
            {startDate}         = 19.07.2021 
            {duration}          = 3 
            </pre>
        <br>
        <br>

        <b>Sample:</b>
        <br>
        <br>
        Projekt "{name}" ({id}) von {customerName} zum {startDate} für {totalCost}
        <br>
        <br>
        Projekt "test" (100) von Wasserstraßen- und Schifffahrtsamt Koblenz zum 19.07.2021 für 1.000,00 €
        </p>
      </div>
    </sx-dialog>
  </sx-page>
</template>

<script>
import { mapGetters } from "vuex";
import {
  appService,
  userService,
  skillService,
  customerService,
  opportunityService,
  oppService,
  userFeedbackService,
} from "@/services";

export default {
  name: "admin-page",
  data() {
    return {
      adminPanelTabIndex: 0,

      editUser: {},
      isCreate: false,
      editUserUnmodified: {},

      mailTemplate: null,

      editSkill: {},
      editCustomer: {},
      editContract: {},
      editSkillDialogTitle: null,
      editCustomerDialogTitle: null,
      editContractDialogTitle: null,

      isCiscoOrSibe: [false, false, false, false],

      rulePhoneNumber: [
        (value) => /\+/.test(value) || "Das Format für die Telofonnummer ist falsch, z.B. +49123456789"
      ]
    };
  },

  watch: {
    adminPanelTabIndex: function (value) {
      this.loadTabItems(value);
    },
    "$store.state.contract.contract": function () {
      this.loadTabItems(this.adminPanelTabIndex);
    },
    'editUser.phoneNumber': function () {
      // wait for component rendering

      if (this.editUser.phoneNumber != null)
        this.validatePhonenumber();
    },

  },
  computed: {
    ...mapGetters("user", ["users",
      "getCiscoGroup",
      "getCiscoSibeGroup",
    ]),
    ...mapGetters("skill", ["skills"]),
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("opportunity", ["opportunities"]),

    skillTableData: function () {
      let skills = [...[], ...this.skills];
      skills.forEach((s) => {
        s["skillNames"] = s.lessThan40PTName + ", ";
        s["skillNames"] += s.between41To160PTName + ", ";
        s["skillNames"] += s.moreThan160PTName;
      });

      return skills;
    },
  },
  created() {
    userService.getAll();
    userService.getAllGroups();
  },

  methods: {
    country: (item) => `${item.dial_code} - ${item.name}`,

    strToFloat(value) {
      if (value === "") {
        return 0;
      } else {
        const parseResult = parseFloat(value);
        return isNaN(parseResult) ? 0 : parseResult;
      }
    },

    validatePhonenumber() {
      if (this.$refs.userPhoneNumber) {
        if (!this.$refs.userPhoneNumber.validate()) {
          // toggle save button
          this.$refs.userEditorTable.isValidPhoneNumber = true;
        } else {
          this.$refs.userEditorTable.isValidPhoneNumber = false;
        }
      }
    },

    async onEditorAction(editorId, type, params) {
      switch (editorId) {
        case "user": {
          switch (type) {
            case "new":
              this.editUser = {
                enabled: true,
              };
              this.isCreate = true;
              this.isCiscoOrSibe = new Array(4).fill(false);
              this.editUserDialogTitle = "Neuen Benutzer erfassen";
              break;
            case "edit":
              this.isCreate = false;
              this.isCiscoOrSibe = new Array(4).fill(false);
              this.editUserDialogTitle = "User bearbeiten";
              this.editUser = Object.assign({}, params.data);
              this.editUser.groups.forEach(group => {
                this.toggleCiscoOrSibe(group.name)
              })
              this.editUserUnmodified = JSON.parse(JSON.stringify(params.data));
              break;
            case "save":
              var request = null;
              if (this.isCreate) {
                if (
                  this.users.filter(
                    (u) =>
                      u.username.trim().toLowerCase() ==
                      this.editUser.username.trim().toLowerCase()
                  ).length > 0
                ) {
                  userFeedbackService.error(`Benutzername existiert bereits`);
                  return;
                }
                request = userService.create(this.editUser);
                request.then(() => {
                  userService.getAll();
                  this.$refs["userEditorTable"].closeEditorDialog();
                  userFeedbackService.success('User created successfully')
                })
                  .catch((error) => {
                    console.error(error);
                    userFeedbackService.error('Error creating User: ' + error);
                  })
              } else {
                // edit phonenumber
                if (this.editUser.phoneNumber != this.editUserUnmodified.phoneNumber) {
                  request = userService.put(this.editUser)

                  request.then(() => {
                    userService.getAll();
                    this.$refs["userEditorTable"].closeEditorDialog();
                    userFeedbackService.success(
                      (this.isCreate ? "" : "Änderungen an ") +
                      `"${this.editUser.username}" gespeichert`
                    );
                  })
                    .catch((err) => {
                      userFeedbackService.error(`Fehler: ${err.response.data}`);
                    });
                }

                request = userService.post(
                  this.editUser,
                  this.editUserUnmodified
                );
                request
                  .then(() => {
                    userService.getAll();
                    this.$refs["userEditorTable"].closeEditorDialog();
                    userFeedbackService.success(
                      (this.isCreate ? "" : "Änderungen an ") +
                      `"${this.editUser.username}" gespeichert`
                    );
                  })
                  .catch((err) => {
                    userFeedbackService.error(`Fehler: ${err.response.data}`);
                  });
              }
              break;
            case "delete":
              userService
                .del(params.data.username)
                .then(() => {
                  userService.getAll();
                  userFeedbackService.info(
                    `Benutzer "${params.data.username}" gelöscht`
                  );
                })
                .catch((err) => {
                  userFeedbackService.error(`Fehler: ${err.response.data}`);
                });
              break;
          }
          break;
        }
        case "opportunity": {
          switch (type) {
            case "delete":
              oppService.getByOpportunityId(params.data.id).then((response) => {
                // push the delete-opportunity-promise onto an array
                let promises = [opportunityService.deleteById(params.data.id)];
                // check if API Returned a valid service
                if (response != null)
                  if (response.id != null)
                    // push the delete-service-promise onto the array
                    promises.push(oppService.deleteById(response.id));

                // promises are ready, wait for result of deleting the service and opportunity
                Promise.all(promises)
                  .then(() => {
                    opportunityService.getAll();
                    userFeedbackService.info(`Opportunity gelöscht`);
                  })
                  .catch((err) => {
                    console.error(err);
                    userFeedbackService.error(`Fehler: ${err}`);
                  });
              });
              break;
          }
          break;
        }
        case "contract": {
          switch (type) {
            case "new":
              this.isCreate = true;
              this.editContract = {};
              this.editContractDialogTitle = "Neuen Rahmenvertrag erfassen";
              break;
            case "save": {
              this.editContract.color = this.editContract.color.hex;
              try {
                await this.$store.dispatch("contract/save", this.editContract);
                await this.$store.dispatch("contract/getAll");
                this.$refs["contractEditorTable"].closeEditorDialog();
                userFeedbackService.info(
                  `Vertrag "${params.data.name}" gespeichert`
                );
              } catch (err) {
                userFeedbackService.error(`Fehler: ${err.response.data}`);
              }
              break;
            }
            case "delete":
              try {
                await this.$store.dispatch("contract/delete", params.data);
                await this.$store.dispatch("contract/getAll");
                userFeedbackService.info(
                  `Vertrag "${params.data.name}" gelöscht`
                );
              } catch (err) {
                userFeedbackService.error(`Fehler: ${err.response.data}`);
              }
              break;
          }
          break;
        }
        case "skill": {
          switch (type) {
            case "new":
              this.isCreate = true;
              this.editSkill = {};
              this.editSkillDialogTitle = "Neuen Skill erfassen";
              break;
            case "edit":
              this.isCreate = false;
              this.editSkill = Object.assign({}, params.data);
              this.editSkillDialogTitle = "Skill bearbeiten";
              break;
            case "save":
              skillService
                .post(this.editSkill)
                .then(() => {
                  skillService.getAll();
                  this.$refs["skillEditorTable"].closeEditorDialog();
                  userFeedbackService.success(
                    `Skill "${this.editSkill.name}" gespeichert`
                  );
                })
                .catch((err) => {
                  userFeedbackService.error(`Fehler: ${err.response.data}`);
                });
              break;
            case "delete":
              skillService
                .del(params.data.id)
                .then(() => {
                  skillService.getAll();
                  userFeedbackService.info(
                    `Skill "${params.data.name}" gelöscht`
                  );
                })
                .catch((err) => {
                  userFeedbackService.error(`Fehler: ${err.response.data}`);
                });
              break;
          }
          break;
        }
        case "customer": {
          switch (type) {
            case "new":
              this.isCreate = true;
              this.editCustomer = {};
              this.editCustomerDialogTitle = "Neuen Customer erfassen";
              break;
            case "edit":
              this.isCreate = false;
              this.editCustomer = Object.assign({}, params.data);
              this.editCustomerDialogTitle = "Customer bearbeiten";
              break;
            case "save":
              if (this.isCreate) {
                if (
                  this.customers.filter(
                    (c) =>
                      c.name.trim().toLowerCase() ==
                      this.editCustomer.name.trim().toLowerCase()
                  ).length > 0
                ) {
                  userFeedbackService.error(`Customer existiert bereits`);
                  return;
                }
              }
              customerService
                .post(this.editCustomer)
                .then(() => {
                  customerService.getAll();
                  this.$refs["customerEditorTable"].closeEditorDialog();
                  userFeedbackService.success(
                    `Customer "${this.editCustomer.name}" gespeichert`
                  );
                })
                .catch((err) => {
                  userFeedbackService.error(`Fehler: ${err.response.data}`);
                });
              break;
            case "delete":
              customerService
                .del(params.data.id)
                .then(() => {
                  customerService.getAll();
                  userFeedbackService.info(
                    `Customer "${params.data.name}" gelöscht`
                  );
                })
                .catch((err) => {
                  userFeedbackService.error(`Fehler: ${err.response.data}`);
                });
              break;
          }
          break;
        }
      }
    },
    toggleCiscoOrSibe(groupName) {
      // isCiscoOrSibe[1] = Sibe; isCiscoOrSibe[2] = Cisco; 
      if ('CiscoSibegroup' == groupName) this.isCiscoOrSibe.splice(2, 1, !this.isCiscoOrSibe[2]);
      if ('Ciscogroup' == groupName) this.isCiscoOrSibe.splice(1, 1, !this.isCiscoOrSibe[1]);
    },

    getTabContractLabel(index) {
      if (this.adminPanelTabIndex == index) {
        return ` (${this.$store.getters["contract/getContract"].name})`;
      }
      return "";
    },
    loadTabItems(index) {
      switch (index) {
        case 0:
          userService.getAll();
          userService.getAllGroups();
          break;
        case 1:
          this.$store.dispatch("contract/getAll");
          break;
        case 3:
          opportunityService.getAll();
          break;
        case 4:
          skillService.getAll();
          break;
        case 5:
          customerService.getAll();
          break;
      }
    },
    showTableCellBoolean(params) {
      let cell = document.createElement("div");
      cell.classList = "sx-table-cell-bool-" + params.value.toString();
      return cell;
    },
    showTableCellList(params) {
      let cell = document.createElement("div");
      cell.innerHTML = params.value.map((group) => `${group.name}`).join(", ");
      return cell;
    },
    isInGroup(groups, group) {
      if (groups == null) return false;
      return groups.filter((g) => g.name == group.name).length > 0;
    },
    onChangeGroup(event, group) {
      if (this.editUser.groups == null) this.editUser.groups = [];
      if (!event) {
        this.editUser.groups = this.editUser.groups.filter(
          (g) =>
            g.name != group.name);
      } else {
        if (this.editUser.groups.filter((g) =>
          g.name == group.name
        ).length < 1)

          this.editUser.groups.push(group);
      }
    },
    onChangeDisabledState(event) {
      console.log(event);
    },
    onSaveAppContext() {
      appService
        .save(this.$store.state.app.context)
        .then(() => {
          userFeedbackService.success(`Änderungen gespeichert`);
        })
        .catch((err) => {
          userFeedbackService.error(`Fehler: ${err.response.data}`);
        });
    },
    onOpenMail() {
      this.$store.dispatch("openMail", {
        template: this.mailTemplate,
        scope: JSON.parse(
          `{"id":"402d2267-0e25-489f-a9eb-0842628d4e2a","version":"40.1","contractName":"Netzwerk 21082","customerName":"Alexander-von-Humboldt-Stiftung","description":"Beschreibung","name":"Test AVH","cC_KAM":"CC KAM","cisco_Emails":"mail1@mail.com; mail2@mail.com","state":"Qualifiziert","stateDescription":"State-Description","createdAt":"2021-08-31T07:07:41.546+00:00","creatorUserEmail":"user@computacenter.com","createdBy":"Vertrieb, MB.FCO","modifiedAt":"2021-10-28T21:35:42.284+00:00","modifiedBy":"Cisco, User","totalCost":16283.5,"startDate":"2021-08-05T00:00:00+00:00","duration":21,"isEdited":true}`
        ),
      });
    },
    onOpenMailDocumentation() {
      this.$refs["mailDocumentation"].show();
    },
    onCopyBearerToken() {
      let token = this.$http.defaults.headers.common["Authorization"];
      navigator.clipboard
        .writeText(token)
        .then(() => {
          userFeedbackService.info(`Token kopiert`);
        })
        .catch((err) => {
          userFeedbackService.error(`Kopieren fehlgeschlagen: ${err}`);
        });
    },
  },
};
</script>

<style lang="sass">
.phoneWrapper
  display: flex
  flex-direction: row
  justify-content: space-around
  gap: 15px

  .countryCode
    width: 20px
  .phoneNumber
.v-tabs-items
  margin-top: -15px !important
  background-color: transparent !important

.admin-page
  max-width: 1280px !important

.admin-page > .sx-card
  min-height: 608px
  .sx-card-text, .sx-card-text > .sx-page-content
    margin-top: 0 !important

.admin-page *.v-tab
  font-size: 12px

.table-info-text
  padding-top: 5px
  padding-bottom: 5px
  font-size: 12px
  font-weight: bold

.sx-box
  margin-top: -15px
  border-radius: 5px
  margin-bottom: -10px
  .v-input--checkbox
    padding: 0px !important
    margin: 0px !important
    margin-top: 10px !important
    margin-bottom: 10px !important
    height: 25px

.sx-account-active
  *
    color: #24387f !important

.sx-account-disabled
  *
    color: #d47f7f !important

.docs
  max-height: 540px
  overflow-y: scroll

.contract-colorpicker

.admin-tabs
  .v-window__container > div
    padding-top: 20px !important
    * .v-tab
      font-size: 8px

.admin-form
  max-width: 400px
  min-height: 450px
.admin-form-bottom-right
  position: absolute
  bottom: 0px
  right: 0px
.admin-form-text
  margin-top: 25px
  &:first-child
    margin-top: 0px
    line-height: 0px
    margin-bottom: 30px
    border-bottom: 1px solid #818181
    span
      background-color: white
      padding-right: 15px
</style>