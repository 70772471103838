import { render, staticRenderFns } from "./AdminPage.vue?vue&type=template&id=316cf2b4&"
import script from "./AdminPage.vue?vue&type=script&lang=js&"
export * from "./AdminPage.vue?vue&type=script&lang=js&"
import style0 from "./AdminPage.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCheckbox,VColorPicker,VSelect,VSpacer,VSwitch,VTab,VTabItem,VTabs,VTabsItems,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build-agent/_work/382/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('316cf2b4')) {
      api.createRecord('316cf2b4', component.options)
    } else {
      api.reload('316cf2b4', component.options)
    }
    module.hot.accept("./AdminPage.vue?vue&type=template&id=316cf2b4&", function () {
      api.rerender('316cf2b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AdminPage.vue"
export default component.exports